import React from 'react';
import Logo from './elementer/Logo';
import Hamb from './elementer/Hamburger';
import { PilNesteGronn } from './elementer/PilNeste';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Bg from '../img/bil1_img8.JPG'

function Tilbud () {
    const addclass=()=>{
        document.querySelector("#tilbud").classList.toggle('tilbud__main--aktiv');
        document.querySelector("#knapp-tilbud").classList.toggle('tilbud__main--knapp-aktiv');
        document.querySelector("#kant-venstre-tilbud").classList.toggle('tilbud__main--kant-venstre-aktiv');
        document.querySelector("#kant-hoyre-tilbud").classList.toggle('tilbud__main--kant-hoyre-aktiv');
    }  
    return (
    <div className='tilbud' >
        <div className='tilbud__banner' style={{backgroundImage: `url(${Bg})`}} alt='bakgrunn'>
            <Logo />
            <Hamb />
        </div>
        <Col md={{ span: 8, offset: 2 }}>
            <Container  className='tilbud__objekt'>
                <Container>
                    <h1 className='tilbud__header'>Førerkort klasse B (Manuell- og automatgir)</h1>
                    <Row classname='tilbud__main--rad'>
                        <Col className='tilbud__main--ingress'>
                            Eleven skal kjøre bil på en ansvarlig måte. Eleven skal ha de kunnskaper og ferdigheter, den selvinnsikt og risikoforståelse, som er nødvendig for å kjøre på en måte som: - er trafikksikker - gir god samhandling - fører til god trafikkavvikling - tar hensyn til helse, miljø og andres behov - er i samsvar med gjeldende regelverk. 
                        </Col>
                    </Row>
                    <div className='tilbud__main--knapp' id='knapp-tilbud'>
                        <div className='tilbud__main--kant-venstre' id='kant-venstre-tilbud' />
                        <div onClick={addclass}>Priser</div>
                        <div className='tilbud__main--kant-hoyre' id='kant-hoyre-tilbud' />
                    </div>
                </Container>
                
                <Container className='tilbud__main tilbud__main--aktiv' id='tilbud'>
                    <Row className='tilbud__rad'>
                        <Col className='tilbud__tabell--container'>
                            <Table className='tilbud__tabell'>
                                <thead>
                                    <tr>
                                        <th>Timer</th>
                                        <th>Priser</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <td>Kjøretime (45 min, i Hallingdal og Numedal)</td>
                                    <td>750,-</td>
                                </tbody>
                                <tbody>
                                    <td>Sikkerhetskurs på øvingsbane (inkludert NAF gebyr)</td>
                                    <td>5900,-</td>
                                </tbody>
                                <tbody>
                                    <td>Sikkerhetskurs på vei</td>
                                    <td>7700,-</td>
                                </tbody>
                                <tbody>
                                    <td>Mørkedemonstrasjon</td>
                                    <td>1700,-</td>
                                </tbody>
                                <tbody>
                                    <td>Leiebil til oppkjøring</td>
                                    <td>2000,-</td>
                                </tbody>
                                <tbody>
                                    <td>Fakturagebyr</td>
                                    <td>99,-</td>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </Col>
       {/*<Container className='tilbud__objekt tilbud__objekt--sist'>
            <Container>
                <p className='tilbud__header'>Førerkort klasse BE (tilhenger)</p>
                <Row classname='tilbud__main--rad'>
                    <Col className='tilbud__main--ingress'>
                        Eleven skal kjøre bil på en ansvarlig måte. Eleven skal ha de kunnskaper og ferdigheter, den selvinnsikt og risikoforståelse, som er nødvendig for å kjøre på en måte som: - er trafikksikker - gir god samhandling - fører til god trafikkavvikling - tar hensyn til helse, miljø og andres behov - er i samsvar med gjeldende regelverk. 
                    </Col>
                </Row>
            </Container>
            <Container className='tilbud__main'>
                <Row className='tilbud__rad'>
                    <Col className='tilbud__tabell--container'>
                        <Table className='tilbud__tabell'>
                            <thead>
                                <tr>
                                    <th>Timer</th>
                                    <th>Priser</th>
                                </tr>
                            </thead>
                            <tbody>
                                <td>Kjøretime</td>
                                <td>850,-</td>
                            </tbody>
                            <tbody>
                                <td>Sikkerhetskurs på vei</td>
                                <td>2600,-</td>
                            </tbody>
                            <tbody>
                                <td>leie bil og tilhenger til oppkjøring</td>
                                <td>2000,-</td>
                            </tbody>
                            <tbody>
                                <td>fakturagebyr</td>
                                <td>99,-</td>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
    </Container>*/}
        {PilNesteGronn('kontakt')}
    </div>
    
    )
}
export default Tilbud;