import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import { Col, Row } from "react-bootstrap";
import { Instagram, Facebook, Linkedin } from 'react-bootstrap-icons';

function Hamburger () {
    const addclass=()=>{
        document.querySelector("#hamburger").classList.toggle('hamburger__content--aktiv');
        document.querySelector("#hamburger-ikon").classList.toggle('hamburger__ikon--aktiv');
    }  
    
    const navigate = useNavigate();
    const handleOnClickHome = useCallback(() => navigate('/', {replace: true}), [navigate]);
    const handleOnClickTilbud = useCallback(() => navigate('/Tilbud', {replace: true}), [navigate]);
    const handleOnClickKontakt = useCallback(() => navigate('/Kontakt', {replace: true}), [navigate]);
    return (
            <div className='hamburger'>
                <div className='hamburger__wrapper' id='hamburger-ikon' onClick={addclass}>
                    <div className="hamburger__strek hamburger__strek--en">
                        <div className='hamburger hamburger__korreksjon hamburger__korreksjon--en'></div>
                    </div>
                    <div className="hamburger__strek hamburger__strek--to">
                        <div className='hamburger hamburger__korreksjon hamburger__korreksjon--to'></div>
                    </div>
                    <div className="hamburger__strek hamburger__strek--tre">
                        <div className='hamburger hamburger__korreksjon hamburger__korreksjon--tre'></div>
                    </div>
                </div>
                <div id='hamburger' className='hamburger__content'>
                    <div className='hamburger__content-wrapper'>
                        <div className='hamburger__element' onClick={handleOnClickHome}>HJEM</div>
                        <div className='hamburger__element' onClick={handleOnClickTilbud}>TILBUD</div>
                        <div className='hamburger__element' onClick={handleOnClickKontakt}>KONTAKT</div>
                    </div>
                    <div className='hamburger__content-wrapper'>
                        <p className='hamburger__info--header'>Kontakt Info</p>
                        <Row className='hamburger__info'>
                            <Col md='3'>Epost: </Col>
                            <Col className='hamburger__info--sist'>post@karls-tafikkskole.no</Col>
                        </Row>
                        <Row className='hamburger__info'>
                            <Col md='3'>Tlf: </Col>
                            <Col className='hamburger__info--sist' md='9'>+47 950 11 725</Col>
                        </Row>
                        <Row className='hamburger__info'>
                            <Col md='3'>Adr.:</Col>
                            <Col className='hamburger__info--sist'>Sentrumsvegen 10</Col>
                        </Row>
                    </div>
                    <div className='hamburger__content-wrapper'>
                        <Row className='hamburger__sosial'>
                            <Col>
                                <Instagram onClick={()=> window.open("https://www.instagram.com/karlstrafikkskole/", "_blank")} className='hamburger__sosial-ikon hamburger__sosial-ikon--instagram'/>
                            </Col>
                            <Col>
                                <Facebook onClick={()=> window.open("https://www.facebook.com/profile.php?id=100086492680794", "_blank")} className='hamburger__sosial-ikon hamburger__sosial-ikon--facebook'/>
                            </Col>
                            <Col>
                                <Linkedin className='hamburger__sosial-ikon hamburger__sosial-ikon--linkedin'/>
                                <p>Kommer snart</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
      );
}

export default Hamburger;
