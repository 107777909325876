import React from 'react';
import Logo from './elementer/Logo'
import Hamb from './elementer/Hamburger'
import Pil from './elementer/PilNeste'
import Bg from '../img/presentasjon.jpg';


function Hjem() {
  console.log(Bg)
  return (
    <div className="hjem" scroll='no'>
        <div className='hjem__bg' style={{backgroundImage: `url(${Bg})`}} alt='bakgrunn'>
          <Logo />
          <Hamb />
          {Pil('tilbud')}
        </div>
    </div>
  );
}

export default Hjem;
