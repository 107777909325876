import React from 'react';
import Logo from './elementer/Logo'
import Hamb from './elementer/Hamburger'
import Bg from '../img/bil1_img5.jpg'
import KontaktInfo from './elementer/KontaktInfo'

function Kontakt () {
    return (
        <div className='kontakt'>
            <div className='kontakt__banner' style={{backgroundImage: `url(${Bg})`}} alt='bakgrunn'>
                <Logo />
                <Hamb />
            </div>
            <KontaktInfo />
        </div>
    )
}
export default Kontakt;