import React from 'react';
import Kontakt from './sider/Kontakt'
import Tilbud from './sider/Tilbud'
import Feil from './Feil'
import Hjem from './sider/Hjem'
import {
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Routes>
        <Route path="/" element={<Hjem />}/>
        <Route path="/tilbud" element={<Tilbud />}/>
        <Route path="/kontakt" element={<Kontakt />}/>
        <Route path="*" element={<Feil /> } />
    </Routes>
  );
}

export default App;
