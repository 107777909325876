import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import ALogo from '../../img/grafisk/LogoSirkel'

function Logo () {
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/', {replace: true}), [navigate]);
    return (
        <div className='logo' onClick={handleOnClick}>
            <ALogo className='logo__sirkel'/>
        </div>
    )
}

export default Logo;