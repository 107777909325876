import ReactDOM from 'react-dom/client';
import React from 'react';
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/index.css';
import {
  MemoryRouter
} from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MemoryRouter>
      <App />
    </MemoryRouter>
  </React.StrictMode>
);
