import React from 'react';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Instagram, Facebook, Linkedin } from 'react-bootstrap-icons';
import Copy from './Copy';
import Mail from './Mail';
import Plassering from './Plassering'

export default function KontaktInfo() {
    const [copy, setCopy] = useState('');
    const info = {
        epost: 'post@karls-trafikkskole.no',
        tlf: '+47 950 11 725',
        adresse: ['Sentrumsvegen 10, 3550 Gol', 'Åsbøvegen 16, 3629 Nore']
    } 
    return (
        <Col md={{ span: 8, offset: 2 }}>
            <Container className='kontakt-info__wrapper'>
                <Container className='kontakt-info__header'>
                    <h1>Kontakt oss</h1>
                </Container>
                <Container className='kontakt-info__innhold'>
                    <div className='kontakt-info__ingress'>
                        <p>
                            Ta kontakt for en uformell prat, bestilling av time eller spørsmål vedrørende Karls trafikkskole
                        </p>
                    </div>
                    <div className='kontakt-info__intro'>
                        <p>Kontakt informasjon:</p>
                    </div>
                    <div className='kontakt-info__element'>
                        <div className='kontakt-info__felt kontakt-info__felt--lang'>{info.epost}</div>
                        <CopyToClipboard text={info.epost}>
                            <Copy onClick={ () => setCopy('Epost adresse kopiert') }
                                className='kontakt-info__knapp'></Copy>
                        </CopyToClipboard>
                        <Mail onClick={ () => window.open('mailto:post@karls-trafikkskole.no?') } className='kontakt-info__knapp kontakt-info__knapp--epost' />
                    </div>
                    <div className='kontakt-info__element'>
                        <div md={{ span: 10}} className='kontakt-info__felt kontakt-info__felt--lang'>{info.tlf}</div>
                        <CopyToClipboard md={{ span: 1}} text={info.tlf}>
                            <Copy onClick={ () => setCopy('Telefonnummer kopiert')} className='kontakt-info__knapp'></Copy>
                        </CopyToClipboard>
                        <div className='kontakt-info__dummy' />
                    </div>
                    <div className='kontakt-info__element'>
                        <div className=' kontakt-info__felt kontakt-info__felt--kort kontakt-info__felt--forst'>{info.adresse[0]}</div>
                        <CopyToClipboard text={info.adresse[0]}>
                            <Copy onClick={ () => setCopy('Adresse på Gol kopiert')} className='kontakt-info__knapp'></Copy>
                        </CopyToClipboard>
                        <a 
                            href='https://www.google.com/maps/place/Sentrumsvegen+10,+3550+Gol/@60.7011408,8.9665253,17z/data=!3m1!4b1!4m5!3m4!1s0x463ff45f85cfe99b:0xe935e10c6540a9ac!8m2!3d60.7011408!4d8.968714' 
                            target='_blank'
                            rel="noopener noreferrer">
                                <Plassering className='kontakt-info__knapp kontakt-info__knapp--forst' />
                        </a>
                        <div className='kontakt-info__felt kontakt-info__felt--kort kontakt-info__felt--sist'>{info.adresse[1]}</div>
                        <CopyToClipboard text={info.adresse[1]}>
                            <Copy onClick={ () => setCopy('Adresse i Nore kopiert')} className='kontakt-info__knapp'></Copy>
                        </CopyToClipboard>                 
                        <a 
                            href='https://www.google.com/maps/place/%C3%85sb%C3%B8vegen+16,+3629+Nore/@60.1691983,9.0054229,17z/data=!3m1!4b1!4m5!3m4!1s0x464083ef00bcd323:0x12b3cf077cde40c9!8m2!3d60.1691983!4d9.0076116' 
                            target='_blank'
                            rel="noopener noreferrer">
                                <Plassering className='kontakt-info__knapp' />
                        </a>
                    </div>
                    <div className='kontakt-info__element'>
                        <div>
                            <div className='kontakt-info__sosial-ingress'>
                                <p>Ta kontakt - eller følg oss - på sosiale medier</p>
                            </div>
                            <Row className='kontakt-info__sosial'>
                                <Col>
                                <Instagram onClick={()=> window.open("https://www.instagram.com/karlstrafikkskole/", "_blank")} className='hamburger__sosial-ikon hamburger__sosial-ikon--instagram'/>
                                </Col>
                                <Col>
                                    <Facebook onClick={()=> window.open("https://www.facebook.com/profile.php?id=100086492680794", "_blank")} className='hamburger__sosial-ikon hamburger__sosial-ikon--facebook'/>
                                </Col>
                                <Col>
                                    <Linkedin className='hamburger__sosial-ikon hamburger__sosial-ikon--linkedin'/>
                                    <p>Kommer snart</p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </Container>
            <div className='kontakt-info__kopi'>
                <div className='kontakt-info__kopi-kant' />
                <p>{copy}</p>
            </div>
        </Col>
    )
}
