import * as React from "react"

const LogoSirkel = (props) => (
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 295 295"
    {...props}
  >
    <defs>
      <style>{".cls-3{fill:#325e37}"}</style>
    </defs>
    <g id="Layer_1-2">
      <circle
        cx={147.5}
        cy={147.5}
        r={147.5}
        style={{
          fill: "#f8f7f7",
        }}
      />
      <path
        d="M204.98 194.99v-63.24h19.18v63.24h-19.18Z"
        style={{
          fill: "#58bfca",
        }}
      />
      <path className="cls-3" d="M159.61 72.6h64.56v15.99h-64.56z" />
      <path
        style={{
          fill: "#f8dba3",
        }}
        d="M70.84 111.42h18.73v83.57H70.84z"
      />
      <path
        style={{
          fill: "#5e5f5f",
        }}
        d="m172.79 119.97-21.41-.05-33.12 32.16 21.54-.17 32.99-31.94z"
      />
      <path
        className="cls-3"
        d="M166.91 194.99h-20.47l19.83 27.41h21.01l-20.37-27.41z"
      />
    </g>
  </svg>
)

export default LogoSirkel
