import * as React from "react"

const Plassering = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    xmlSpace="preserve"
    {...props}
  >
    <path d="M500 804.3c123.8-134.1 283.7-350.7 283.7-459.1 0-154.7-128.9-283.7-283.7-283.7-154.7-5.2-283.7 123.8-283.7 283.7 0 108.4 159.9 325 283.7 459.1m0 77.4S164.7 531 164.7 345.3 314.3 10 500 10c185.7 0 335.3 149.6 335.3 335.3 0 185.6-335.3 536.4-335.3 536.4z" />
    <path d="M500 458.7c61.9 0 113.5-51.6 113.5-113.5S561.9 231.7 500 231.7s-113.5 51.6-113.5 113.5c0 62 51.6 113.5 113.5 113.5m0 51.6c-92.8 0-165.1-72.2-165.1-165.1 0-92.8 72.2-165.1 165.1-165.1 92.8 0 165.1 72.2 165.1 165.1 0 92.9-72.3 165.1-165.1 165.1zM634.1 773.4V825c82.5 15.5 134.1 36.1 149.6 51.6-20.6 20.6-113.5 61.9-283.7 61.9-165.1 0-263.1-41.3-283.7-61.9 15.5-15.5 67.1-41.3 159.9-51.6v-51.6c-123.8 15.5-211.5 56.7-211.5 103.2C164.7 938.4 314.3 990 500 990c185.7 0 335.3-51.6 335.3-113.5 0-46.4-82.6-87.7-201.2-103.1z" />
  </svg>
)

export default Plassering
