import React, {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import { ChevronDown } from 'react-bootstrap-icons';

export default function PilNeste(path) {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate(`/${path}`, {replace: true}), [navigate]);

  return (
    <div className='pil-neste' onClick={handleOnClick}>
      <p>
        <div className="pil-neste__kant-korrektur" />
        <ChevronDown className='pil-neste__pil' />
      </p>
      <div className='pil-neste__tekst'>
        {path.toUpperCase()}
      </div>
      
    </div>
    
  );
}

export function PilNesteGronn (path) {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate(`/${path}`, {replace: true}), [navigate]);

  return (
    <div className='gronn-pil-neste' onClick={handleOnClick}>
      <p>
        <div className="gronn-pil-neste__kant-korrektur" />
        <ChevronDown className='gronn-pil-neste__pil' />
      </p>
      <div className='gronn-pil-neste__tekst'>
        {path.toUpperCase()}
      </div>
    </div>
    
  );
}
